import { useEffect } from 'react';

const Redirector = ({ url }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = url;
    }, 250); // 1000 milliseconds = 1 seconds

    // Clean up function to clear the timer if the component unmounts before the redirection
    return () => clearTimeout(timer);
  }, [url]);

  return null;
};

export default Redirector;