import ulas from "./ulas.png";
import "./App.css";
import Redirector from "./Redirector";

const redirectLink = "https://calendar.app.google/pxW5q4n6zJk4YiJZ8";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={ulas} className="App-logo" alt="ulas" />
        <p>0xulas.eth</p>
        <p>Redirecting to the calendar page.</p>
        <a
          className="App-link"
          href={redirectLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          Click if you are not redirected.
        </a>
      </header>
      <div className="App">
        <Redirector url={redirectLink} />
      </div>
    </div>
  );
}

export default App;
